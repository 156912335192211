import { render, staticRenderFns } from "./TermsPushDialog.vue?vue&type=template&id=300b4087&scoped=true&"
import script from "./TermsPushDialog.vue?vue&type=script&lang=js&"
export * from "./TermsPushDialog.vue?vue&type=script&lang=js&"
import style0 from "./TermsPushDialog.vue?vue&type=style&index=0&id=300b4087&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "300b4087",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardActions,VCardText,VDialog})
