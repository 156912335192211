<template>
  <div class="terms">
    <!--    <div class="pushRecv-push">
      금융상품 추천 PUSH
      <toggle-button
        :sync="true"
        :value="form['pushRecv']"
        :switch-color="{ checked: '#FFFFFF', unchecked: '#FFFFFF' }"
        :color="{ checked: '#0A65EE', unchecked: '#F4F4F4' }"
        :width="40"
        :height="24"
        @change="(v) => onChangePushRadio('pushRecv', v)"
      />
    </div>-->

    <div v-for="(term, i) in termsList" :key="term.id" :class="term.className">
      <div class="terms-title" @click="onClickPanel(i)">
        <span v-html="term.title"></span>
        <span v-if="term.className" class="icon icon-chevron-up-black"></span>
        <span v-else class="icon icon-chevron-down-black"></span>
      </div>

      <transition name="accordion" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
        <div v-show="term.className" class="terms-content">
          <div class="terms-content-agree">
            다음 항목에 동의합니다.
            <toggle-button
              :sync="true"
              :value="form[term.id]"
              :switch-color="{ checked: '#FFFFFF', unchecked: '#FFFFFF' }"
              :color="{ checked: '#444444', unchecked: '#F4F4F4' }"
              :width="28"
              :height="16"
              @change="(v) => onChangeRadio(term.id, v)"
            />
          </div>
          <div class="terms-content-text" v-html="term.content"></div>
        </div>
      </transition>
    </div>

    <terms-dialog :dialog="dialog" v-model="dialog.show" />
    <terms-push-dialog v-if="pushDialog" v-model="pushDialog" @cancel="onChangeCancel" />
  </div>
</template>
<script>
import { isSuccess } from "@/helpers";
import TermsDialog from "@/components/terms/TermsDialog";
import TermsPushDialog from "@/components/terms/TermsPushDialog";
import { uiActions, userActions, userGetters } from "@/store";
import { AppCommand } from "@/api/bridge";
import { OCB_TRACK_ID } from "@/const";
import { gaTracker } from "@/helpers/tracer";

/*const DIALOG_DICT = {
  trdInfo: {
    // 선택 동의
    title: "",
    content: `본 동의 철회 시,<br />금융상품 추천 서비스를<br />원활하게 제공받을 수 없습니다.`,
    footer: ""
  }
};*/

export default {
  name: "Terms",
  components: { TermsPushDialog, TermsDialog },
  data: () => ({
    pushDialog: false,
    dialog: {
      show: false,
      title: "",
      content: "",
      footer: ""
    },
    panel: [],
    form: {
      trdInfo: false
    },
    termsList: [
      {
        id: "trdInfo",
        title: "개인정보의 제3자 제공동의 (선택)",
        content: `에스케이플래닛㈜는 「개인정보보호법」 제17조에 따라, 제3자에게 제공할 경우 본인의 동의를 얻어야 합니다. 이에 본인의 개인(신용)정보를 아래와 같이 제3자에게 제공하는 것에 동의합니다.<br/><br/>
에스케이플래닛㈜ 귀중<br/>
        <table>
          <thead>
            <tr>
              <th>개인정보를 제공받는 자</th>
              <th>제공받는 자의 개인정보 이용 목적</th>
              <th>제공받는 개인정보 항목</th>
              <th>제공받는 자의 개인정보 보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-weight: bold">
              <td>오버테이크 주식회사</td>
              <td>- 오버테이크의 금융상품 추천 서비스의  개인 맞춤/연계서비스 제공<br/>- 오버테이크의 금융상품 추천 서비스 이용현황통계분석 및 활용</td>
              <td style="font-weight: normal">회원구분번호, 이름, 생년월일, 성별, OS플랫폼</td>
              <td>본 동의 철회 시 또는 동의일로부터 90일 보관 후 지체없이 파기</td>
            </tr>
          </tbody>
        </table><br/>
        ※ 본 동의사항에 동의하지 않으실 수 있으며, 본 동의를 거부할 경우에도 서비스 이용이 가능합니다.<br/>
        ※ 본 동의를 철회하고자 할 경우 오버테이크의 광고페이지 하단에서 동의 철회 요청을 할 수 있습니다.
        `.replace(/(?:\r\n|\r|\n)/g, ""),
        className: "",
        required: true
      }
    ]
  }),

  computed: {
    ...userGetters(["agreeInfoForm", "trackCdType"]),
    typeQuery() {
      return this.$route.query.type;
    }
  },

  watch: {
    agreeInfoForm: {
      immediate: true,
      handler() {
        this.form = { ...this.agreeInfoForm };
      }
    }
  },

  methods: {
    ...uiActions(["setGlobalSnackbar"]),
    ...userActions(["setAgreeInfo"]),

    async updateAgreeData(form) {
      // 여기서는 push를 개별로 컨트롤 할 수 있기 때문에 그대로 넘긴다.
      const data = await this.setAgreeInfo({
        agreeInfoForm: {
          ...form
        }
      });

      if (!isSuccess(data)) {
        this.setGlobalSnackbar({ message: "정보 저장에 실패했습니다." });
      }
    },
    /*async onChangePushRadio(id, { value }) {
      this.pushDialog = false;

      if (value) {
        // 다른 팝업 창은 닫는다.
        this.dialog = { show: false, title: "", content: "", footer: "" };
      }

      const condition = { ...this.form };
      delete condition[id];

      // 기존 조건이 false이고, 다른 조건들 중에 false가 1개라도 있으면 동의를 받을 수 있도록 모달을 띄운다.
      if (!this.form[id] && [...Object.values(condition)].some((v) => v === false)) {
        // 모달에서 response가 오면 해당 response로 switch값을 update한다.
        this.pushDialog = true;
      } else {
        await this.updateAgreeData({ ...this.form, [id]: value });
      }
    },*/
    async onChangeRadio(id, { value }) {
      // 선택 동의
      if (value) {
        // PUSH 동의 창은 닫는다.
        this.pushDialog = false;
        //this.dialog = { show: false, title: "", content: "", footer: "" };

        await this.updateAgreeData({ ...this.form, [id]: true });

        gaTracker({
          category: "약관 메인 > 제3자 제공동의",
          action: `동의 토글`,
          label: `${this.trackCdType}`
        });

        this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE, action_id: "thirdagree.accepttoggle", common_code: "YES" } });
      } else {
        // OCB 취소로그는 해당 팝업에서 처리한다.
        this.pushDialog = true;
        //this.dialog = { ...DIALOG_DICT[id], show: true };
      }
    },
    onChangeCancel(isCancel) {
      if (isCancel) {
        gaTracker({
          category: `약관 메인 > 제3자 제공동의`,
          action: `철회 토글`,
          label: `${this.trackCdType}`
        });

        this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE, action_id: "thirdagree.accepttoggle", common_code: "NO" } });
      }
    },
    onClickPanel(index) {
      if (Array.isArray(index)) {
        if (this.panel.length !== 0) {
          this.panel.forEach((i) => {
            this.termsList[i].className = "";
          });
        }

        index.forEach((i) => {
          this.termsList[i].className = "terms-panel-border";
        });

        this.panel = [...index];
      } else {
        if (this.panel.includes(index)) {
          this.termsList[index].className = "";
          this.panel = this.panel.filter((i) => i !== index);
        } else {
          if (this.panel.length !== 0) {
            this.panel.forEach((i) => {
              this.termsList[i].className = "";
            });
          }

          this.termsList[index].className = "terms-panel-border";

          this.panel.push(index);
        }
      }
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    }
  },

  mounted() {
    this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE } });
  },

  beforeMount() {
    this.onClickPanel(0);
    /*if (this.typeQuery) {
      for (let i = 0; i < this.termsList.length; i++) {
        const term = this.termsList[i];
        if (term.id === this.typeQuery) {
          this.onClickPanel(i);
          break;
        }
      }
    }*/
  }
};
</script>

<style lang="scss" scoped>
@mixin term-title {
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;

  @include normal_16_24();

  color: $color-grey-6;
}

.terms {
  &-panel-border {
    box-sizing: border-box;
    border: 1px solid $color-grey-1;
  }

  .pushRecv-push {
    @include term-title();
  }

  &-title {
    @include term-title();
    position: relative;
    cursor: pointer;
  }

  &-content {
    overflow: hidden;
    transition: 250ms ease-out;
    padding: 20px 24px;

    &-agree {
      padding: 16px;
      min-height: 52px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include normal_14_20();
      color: $color-grey-5;

      border: 1px solid $color-grey-1;
      box-sizing: border-box;
      border-radius: 8px;
    }

    &-text {
      word-break: break-word;
      margin-top: 16px;
      @include normal_13_19();
      color: $color-grey-4;
    }
  }
}

::v-deep {
  table {
    border: 1px solid #444444;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #444444;
    }
  }
}
</style>
