<template>
  <v-dialog :value="value" @click:outside="onClickOutside">
    <v-card class="dialog-agree">
      <v-card-text class="dialog-agree-inner">
        <p class="dialog-agree-title" v-html="dialog.title"></p>
        <p class="dialog-agree-content" v-html="dialog.content"></p>
        <p class="dialog-agree-footer" v-html="dialog.footer"></p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TermsDialog",
  props: {
    dialog: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickOutside() {
      this.$emit("input", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-agree {
  &-inner {
    color: $color-grey-6 !important;
    text-align: center;
    padding: 25px 20px !important;
  }

  &-title {
    @include normal_13_22();
  }

  &-content {
    margin-top: 10px;

    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
  }

  &-footer {
    margin-top: 10px;

    @include normal_13_22();
  }
}
</style>
