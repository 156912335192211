<template>
  <v-dialog :value="value" persistent>
    <v-card class="dialog-agree">
      <v-card-text class="dialog-agree-inner">
        <h1 class="dialog-agree-title">본 동의 철회 시,<br />금융상품 추천 서비스를 제공받을 수 없습니다.<br />철회하시겠습니까?</h1>
      </v-card-text>
      <v-card-actions class="dialog-agree-actions">
        <button type="button" class="button button-confirm" @click.prevent="cancelAgree" :disabled="isProcessing">철회하기</button>
        <button type="button" class="button" @click.prevent="closeTermsPushDialog(true)">닫기</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { uiActions, userActions, userGetters } from "@/store";
import { isSuccess } from "@/helpers";
import { AppCommand } from "@/api/bridge";
import { OCB_TRACK_ID } from "@/const";
import { gaTracker } from "@/helpers/tracer";

export default {
  name: "TermsPushDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isProcessing: false,
    form: {
      trdInfo: false
    }
  }),
  computed: {
    ...userGetters(["agreeInfoForm", "isTrdInfo", "trackCdType"])
  },
  methods: {
    ...uiActions(["setGlobalSnackbar"]),
    ...userActions(["setAgreeInfo"]),
    async cancelAgree() {
      gaTracker({
        category: `약관 철회 모달`,
        action: `철회하기 클릭`,
        label: `${this.trackCdType}`
      });

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE_POPUP, action_id: "bottom_tap.okbtn" } });

      this.isProcessing = true;
      const data = await this.setAgreeInfo({
        agreeInfoForm: {
          ...this.form
        }
      });

      this.isProcessing = false;
      if (isSuccess(data)) {
        this.closeTermsPushDialog();

        this.$emit("cancel", true);
      } else {
        this.setGlobalSnackbar({ message: "정보 저장에 실패했습니다." });
        setTimeout(() => {
          this.closeTermsPushDialog();
        }, 1000);
      }
    },
    closeTermsPushDialog(fromBtn) {
      if (fromBtn) {
        gaTracker({
          category: `약관 철회 모달`,
          action: `닫기 클릭`,
          label: `${this.trackCdType}`
        });

        this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE_POPUP, action_id: "bottom_tap.closebtn" } });
      }

      this.$emit("input", false);
    }
  },
  mounted() {
    this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.THIRD_AGREE_POPUP } });
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-dialog > .v-card > .v-card__text {
    padding: 0;
  }
  .v-dialog > .v-card > .v-card__actions {
    padding: 0;
  }

  .dialog-agree {
    border-radius: 8px;
    padding: 24px 20px 10px 20px;
    position: relative;
    box-shadow: none;

    &-title {
      margin: 0;
      font-size: rem(18px);
      font-weight: 500;
      line-height: (27/18);
      letter-spacing: -1px;
      text-align: center;
      color: $color-grey-5;
    }

    &-text {
      margin: 10px 0 0;
      font-size: 13px;
      line-height: (20/13);
      text-align: center;
      letter-spacing: -0.02em;
      opacity: 0.8;
    }

    &-list {
      padding: 0;
      margin: 15px 0 0;
      border: 1px solid rgba(231, 231, 231, 0.7);
      border-radius: 6px;
      list-style: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #f5f5f5;

      padding: 16px 20px 16px 20px;

      font-size: rem(13px);
      line-height: (22/13);
      letter-spacing: -0.5px;

      &:first-child {
        border-width: 0;
      }

      &.disabled {
        opacity: 0.5;
      }

      .text {
        cursor: pointer;
      }

      &.active {
        .text {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      .custom-check {
        input {
          display: none;
        }

        input:checked + .check {
          border-color: $color-grey-6;

          &:after {
            display: block;
            background-image: url(~@/assets/icons/icon-dialog-check-black@2x.png);
          }
        }

        .check {
          overflow: hidden;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          border: 2px solid #f5f5f5;
          border-radius: 500em;
          vertical-align: top;

          &:after {
            display: none;
            width: (22px/2);
            height: (16px/2);
            background: url(~@/assets/icons/icon-check-blue@2x.png) no-repeat -1px 0;
            background-size: contain;
            content: "";
          }
        }
      }
    }

    &-actions {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      @include normal_16_24(-0.014em);
      text-align: center;

      .button {
        border-radius: 8px;
        width: 100%;
        height: 52px;

        &-confirm {
          margin-bottom: 4px;
          background-color: $color-grey-6;
          color: $color-white;

          &[disabled] {
            background-color: $color-grey-2;
          }
        }
      }
    }
  }
}
</style>
